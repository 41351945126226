import React from "react"
import { useIntl } from "react-intl"

import { makeStyles } from "@material-ui/core/styles"
import Chip from "@material-ui/core/Chip"

const useStyles = makeStyles(theme => ({
  geoRegion: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    marginBottom: "2px",
    marginTop: "2px",
  },
}))

const GeoRegionChip = props => {
  const classes = useStyles(props)
  const intl = useIntl()
  const { size, region } = props

  return (
    <Chip
      variant="outlined"
      size={size}
      label={
        region.includes("region.") ? intl.formatMessage({ id: region }) : region
      }
      className={classes.geoRegion}
    />
  )
}

export default GeoRegionChip
