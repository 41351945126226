import React from "react"
import { useIntl } from "react-intl"

import DirectionsRunIcon from "@material-ui/icons/DirectionsRun"
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike"
import SportsHandballIcon from "@material-ui/icons/SportsHandball"
import LocalSeeIcon from "@material-ui/icons/LocalSee"
import TransformIcon from "@material-ui/icons/Transform"
import HomeIcon from "@material-ui/icons/Home"
import FilterHdrIcon from "@material-ui/icons/FilterHdr"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"

const EventTypeIcon = props => {
  const intl = useIntl()
  const { type } = props

  switch (type) {
    case "hike":
      return (
        <DirectionsRunIcon
          titleAccess={intl.formatMessage({ id: "type.hike" })}
        />
      )
    case "bike":
      return (
        <DirectionsBikeIcon
          titleAccess={intl.formatMessage({ id: "type.bike" })}
        />
      )
    case "activity":
      return (
        <SportsHandballIcon
          titleAccess={intl.formatMessage({ id: "type.activity" })}
        />
      )
    case "location":
      return (
        <LocalSeeIcon
          titleAccess={intl.formatMessage({ id: "type.location" })}
        />
      )
    case "transport":
      return (
        <TransformIcon
          titleAccess={intl.formatMessage({ id: "type.transport" })}
        />
      )
    case "accomodation":
      return (
        <HomeIcon
          titleAccess={intl.formatMessage({ id: "type.accomodation" })}
        />
      )
    case "camping":
      return (
        <FilterHdrIcon
          titleAccess={intl.formatMessage({ id: "type.camping" })}
        />
      )
    default:
      return (
        <FiberManualRecordIcon
          titleAccess={intl.formatMessage({ id: "type.other" })}
        />
      )
  }
}

export default EventTypeIcon
