import React from "react"
import { useIntl } from "react-intl"
import { graphql } from "gatsby"

import BaseStructure from "../components/site/baseStructure"
import Seo from "../components/site/seo"
import EventTitleImage from "../components/eventDetails/eventTitleImage"
import EventHeader from "../components/eventDetails/eventHeader"
import EventHikingData from "../components/eventDetails/eventHikingData"
import EventDescription from "../components/eventDetails/eventDescription"
import EventPictures from "../components/eventDetails/eventPictures"
import EventNavigation from "../components/eventDetails/eventNavigation"
import VideoList from "../components/elements/videoList"
import SubHeading from "../components/elements/subHeading"
import { imgurUrlFromId } from "../utils/imgurService"

import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"

const EventTemplate = ({ data, pageContext, location }) => {
  const intl = useIntl()
  const { event, mdx, gpx, images } = data
  const trip = {
    ...data.trip,
    //add title attribute for trip from mdx
    title: data.tripsMdx.edges.find(
      n => n.node.fields.slug === data.trip.fields.slug
    ).node.frontmatter.title,
  }
  const { title, subtitle } = mdx.frontmatter
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const titleImage = event.titleImage ? event.titleImage : event.featuredImage

  return (
    <BaseStructure location={location} title={siteTitle}>
      <Seo title={title} description={subtitle} />
      <EventTitleImage
        imageSrc={imgurUrlFromId(titleImage.imgurId)}
        alignment={titleImage.alignment ? titleImage.alignment : false}
        title={title}
      />
      <Container className="standardWidth">
        <EventHeader event={event} subtitle={subtitle} />

        {event.hikingData && event.hikingData.distance > 0 && (
          <React.Fragment>
            <Divider />
            <SubHeading
              title={intl.formatMessage({ id: "page.event.hikingData.title" })}
            />
            <Container>
              <EventHikingData
                data={event.hikingData}
                gpxURL={gpx ? gpx.publicURL : false}
              />
            </Container>
          </React.Fragment>
        )}

        {event.featuredVideo && (
          <React.Fragment>
            <Divider />
            <SubHeading
              title={intl.formatMessage({ id: "page.event.videos.title" })}
            />
            <Container>
              <VideoList
                featuredVideo={event.featuredVideo}
                videos={event.videos ? event.videos : false}
              />
            </Container>
          </React.Fragment>
        )}

        <Divider />
        <SubHeading
          title={intl.formatMessage({ id: "page.event.description.title" })}
        />
        <Container>
          <EventDescription
            event={event}
            trip={trip}
            mdx={mdx ? mdx.body : false}
          />
        </Container>

        <Divider />
        <SubHeading
          title={intl.formatMessage({ id: "page.event.pictures.title" })}
        />
        <Container>
          <EventPictures images={images.images} />
        </Container>

        <Divider />
        <EventNavigation previous={previous} next={next} />
      </Container>
    </BaseStructure>
  )
}

export default EventTemplate

export const pageQuery = graphql`
  query EventBySlug(
    $slug: String!
    $locale: String!
    $imgurAlbumId: String!
    $tripId: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    event: eventsJsonJson(fields: { slug: { eq: $slug } }) {
      type
      rating
      date
      coordinates {
        latitude
        longitude
      }
      polRegion
      geoRegions
      places
      labels
      attributes {
        admission
        withDog
      }
      hikingData {
        down
        high
        distance
        time
        up
        gpx {
          mapoutId
        }
      }
      videos {
        type
        id
      }
      featuredVideo {
        youtubeId
      }
      images {
        imgurAlbumId
      }
      featuredImage {
        imgurId
        alignment
      }
      titleImage {
        imgurId
        alignment
      }
    }
    mdx: mdx(fields: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      body
      frontmatter {
        title
        subtitle
      }
    }
    gpx: file(extension: { eq: "gpx" }, fields: { slug: { eq: $slug } }) {
      publicURL
    }
    images: imgurAlbum(imgurId: { eq: $imgurAlbumId }) {
      images {
        id
      }
    }
    trip: tripsJsonJson(tripId: { eq: $tripId }) {
      fields {
        slug
      }
    }
    tripsMdx: allMdx(filter: { fields: { locale: { eq: $locale } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
