import React from "react"
import { useIntl } from "react-intl"

import { mapoutUrlFromId } from "../../utils/mapoutService"

//import { makeStyles } from "@material-ui/core/styles"
import Link from "@material-ui/core/Link"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import GetAppIcon from "@material-ui/icons/GetApp"
import MapIcon from "@material-ui/icons/Map"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import TrendingDownIcon from "@material-ui/icons/TrendingDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import TimerIcon from "@material-ui/icons/Timer"

//const useStyles = makeStyles(theme => ({}))

const EventHikingData = props => {
  //const classes = useStyles(props)
  const intl = useIntl()
  const { data } = props

  return (
    <Container className="noHorizontalSpacing flexStart flexWrap spaceL">
      {data.distance && (
        <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceS">
          <SyncAltIcon
            titleAccess={intl.formatMessage({
              id: "component.hikingData.distance",
            })}
          />{" "}
          <Typography variant="body1">{data.distance} km</Typography>
        </Container>
      )}
      {data.up && (
        <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceS">
          <TrendingUpIcon
            titleAccess={intl.formatMessage({
              id: "component.hikingData.up",
            })}
          />{" "}
          <Typography variant="body1">{data.up} m</Typography>
        </Container>
      )}
      {data.down && (
        <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceS">
          <TrendingDownIcon
            titleAccess={intl.formatMessage({
              id: "component.hikingData.down",
            })}
          />{" "}
          <Typography variant="body1">{data.down} m</Typography>
        </Container>
      )}
      {data.high && (
        <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceS">
          <ArrowDropUpIcon
            titleAccess={intl.formatMessage({
              id: "component.hikingData.high",
            })}
          />{" "}
          <Typography variant="body1">{data.high} m</Typography>
        </Container>
      )}
      {data.time && (
        <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceS">
          <TimerIcon
            titleAccess={intl.formatMessage({
              id: "component.hikingData.time",
            })}
          />{" "}
          <Typography variant="body1">{data.time} h</Typography>
        </Container>
      )}
      <div className="flexSpacer"></div>
      {props.gpxURL && (
        <Link
          href={props.gpxURL}
          title={intl.formatMessage({
            id: "component.hikingData.gpx.download",
          })}
          download
        >
          <GetAppIcon />
        </Link>
      )}
      {data.gpx && data.gpx.mapoutId && data.gpx.mapoutId !== "#" && (
        <Link
          href={mapoutUrlFromId(data.gpx.mapoutId)}
          title={intl.formatMessage({
            id: "component.hikingData.gpx.mapout",
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MapIcon />
        </Link>
      )}
    </Container>
  )
}

export default EventHikingData
