import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  outerContainer: {
    height: "50vh",
    minHeight: "300px",
    position: "relative",
    display: "block",
  },
  innerContainer: {
    overflow: "hidden",
    height: "100%",
    width: "100%",
    position: "relative",
  },
  img: {
    height: "100%",
    width: "100%",
    display: "block",
    position: "absolute",
    objectFit: "cover",
    objectPosition: "center center",
  },
  imgTop: {
    objectPosition: "top center !important",
  },
  imgBottom: {
    objectPosition: "bottom center !important",
  },
  title: {
    position: "absolute",
    width: "100%",
    maxWidth: "inherit",
    bottom: "8px",
    zIndex: "1",
    fontWeight: "600",
    fontSize: "3em",
    lineHeight: 1,
    color: theme.palette.text.white,
  },
}))

const EventTitleImage = props => {
  const classes = useStyles(props)
  return (
    <Container className={classes.outerContainer + " fullWidth"}>
      <Container className={classes.innerContainer + " fullWidth"}>
        <img
          className={
            classes.img +
            (props.alignment === "top" ? " " + classes.imgTop : "") +
            (props.alignment === "bottom" ? " " + classes.imgBottom : "")
          }
          src={props.imageSrc}
          alt={props.title}
        />
        <Container className="standardWidth">
          <Typography variant="button" component="h2" className={classes.title}>
            {props.title}
          </Typography>
        </Container>
      </Container>
    </Container>
  )
}

export default EventTitleImage
