import React from "react"
import { useIntl } from "react-intl"
import { useLocalization } from "gatsby-theme-i18n"
import { Link as InternalLink } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import GeoRegionChip from "../elements/geoRegionChip"
import LabelChip from "../elements/labelChip"
import { formatDateStd, localizedLink } from "../../utils/localizationService"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import DateRangeIcon from "@material-ui/icons/DateRange"
import PlaceIcon from "@material-ui/icons/Place"
import LocalSeeIcon from "@material-ui/icons/LocalSee"
import CallMadeIcon from "@material-ui/icons/CallMade"

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
  },
}))

const EventDescription = props => {
  const classes = useStyles(props)
  const { locale, config } = useLocalization()
  const intl = useIntl()
  const { event, trip, mdx } = props
  const { date, polRegion, geoRegions, places, labels } = event

  return (
    <Container className="noHorizontalSpacing">
      <Container className="noHorizontalSpacing flexBetween flexWrap marginBottomM">
        <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceM">
          <DateRangeIcon
            titleAccess={intl.formatMessage({ id: "component.event.date" })}
          />
          <Typography variant="body1">
            {formatDateStd(date, locale, config)}
          </Typography>
        </Container>
        <div className="flexSpacer"></div>
        <InternalLink
          to={localizedLink("/trip" + trip.fields.slug, locale)}
          className={classes.link + " flexStart flexWrap noLinkStyle"}
        >
          <CallMadeIcon /> {trip.title}
        </InternalLink>
      </Container>

      {polRegion && (
        <Container className="noHorizontalSpacing flexStart marginBottomM spaceM">
          <PlaceIcon
            titleAccess={intl.formatMessage({
              id: "component.event.polRegions",
            })}
          />
          <Typography variant="body1">
            {polRegion
              .map(region =>
                region.includes("region.")
                  ? intl.formatMessage({ id: region })
                  : region
              )
              .slice(1)
              .join(", ")}
          </Typography>
          {geoRegions && geoRegions.length > 0 && (
            <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceM">
              {geoRegions.map(geoRegion => (
                <GeoRegionChip
                  key={geoRegion}
                  region={geoRegion}
                  size="small"
                />
              ))}
            </Container>
          )}
        </Container>
      )}

      {places && places.length > 0 && (
        <Container className="noHorizontalSpacing flexStart marginBottomM spaceM">
          <LocalSeeIcon
            titleAccess={intl.formatMessage({ id: "component.event.places" })}
          />
          <Typography variant="body1">
            {places
              .map(place =>
                place.includes("region.")
                  ? intl.formatMessage({ id: place })
                  : place
              )
              .join(", ")}
          </Typography>
        </Container>
      )}

      {props.mdx && (
        <Container className="noHorizontalSpacing marginBottomM">
          <Typography variant="body1">
            <MDXRenderer>{mdx}</MDXRenderer>
          </Typography>
        </Container>
      )}

      {labels && labels.length > 0 && (
        <Container className="noHorizontalSpacing flexStart flexWrap marginBottomM">
          <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceM">
            {labels.map(label => (
              <LabelChip key={label} label={label} size="small" />
            ))}
          </Container>
        </Container>
      )}
    </Container>
  )
}

export default EventDescription
