import React from "react"
import { Link as InternalLink } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"

import { localizedLink } from "../../utils/localizationService"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
  },
}))

const EventNavigation = props => {
  const classes = useStyles(props)
  const { locale } = useLocalization()

  return (
    <Container className="standardWidth flexBetween flexWrap">
      <Typography>
        {props.previous && (
          <InternalLink
            to={localizedLink("/event" + props.previous.fields.slug, locale)}
            className={classes.link + " flexStart flexWrap noLinkStyle"}
            rel="prev"
          >
            <ArrowBackIcon /> {props.previous.title}
          </InternalLink>
        )}
      </Typography>
      <Typography>
        {props.next && (
          <InternalLink
            to={localizedLink("/event" + props.next.fields.slug, locale)}
            className={classes.link + " flexStart flexWrap noLinkStyle"}
            rel="next"
          >
            {props.next.title} <ArrowForwardIcon />
          </InternalLink>
        )}
      </Typography>
    </Container>
  )
}

export default EventNavigation
