import React from "react"

import ImgurImageGallery from "../elements/imgurImageGallery"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

const useStyles = makeStyles(theme => ({
  galleryContainer: {
    backgroundColor: theme.palette.common.white,
    overflow: "auto",
  },
}))

const EventPictures = props => {
  const classes = useStyles(props)

  return (
    <Container className={classes.galleryContainer + " noHorizontalSpacing"}>
      <ImgurImageGallery imageIds={props.images} />
    </Container>
  )
}

export default EventPictures
