import React from "react"
import { useIntl } from "react-intl"

import { makeStyles } from "@material-ui/core/styles"
import Chip from "@material-ui/core/Chip"

const useStyles = makeStyles(theme => ({
  label: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.white,
    marginBottom: "2px",
    marginTop: "2px",
  },
}))

const LabelChip = props => {
  const classes = useStyles(props)
  const intl = useIntl()

  return (
    <Chip
      size={props.size}
      label={intl.formatMessage({ id: props.label })}
      className={classes.label}
    />
  )
}

export default LabelChip
