import React from "react"
import Gallery from "react-grid-gallery"
import { imgurUrlFromId } from "../../utils/imgurService"

const imgurImageGallery = props => {
  const images = props.imageIds.map(image => {
    return {
      src: imgurUrlFromId(image.id),
      thumbnail: imgurUrlFromId(image.id, "l"),
      thumbnailWidth: 320,
      thumbnailHeight: (image.height / image.width) * 320,
    }
  })

  return (
    <Gallery
      images={images}
      enableImageSelection={false}
      rowHeight={160}
      backdropClosesModal={true}
      imageCountSeparator="/"
      showLightboxThumbnails={true}
    />
  )
}

export default imgurImageGallery
