import React from "react"

import EventTypeIcon from "../elements/eventTypeIcon"
import EventAttributeIcon from "../elements/eventAttributeIcon"
import HighlightChips from "../elements/highlightChips"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: "10",
    fontSize: "1.6em",
    color: theme.palette.secondary.light,
  },
  type: {
    fontWeight: "200",
    fontSize: "1.6em",
    color: theme.palette.secondary.main,
  },
}))

const EventHeader = props => {
  const classes = useStyles(props)
  const { event, subtitle } = props
  const { type, rating } = event
  return (
    <Container className="noHorizontalSpacing flexBetween flexWrap marginTopS">
      <Container className="noHorizontalSpacing unsetWidth">
        <Typography variant="h5" component="h3" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </Container>
      <div className="flexSpacer"></div>
      <Container className="noHorizontalSpacing flexStart flexWrap unsetWidth spaceL">
        {event.attributes &&
          event.attributes.withDog &&
          Object.keys(event.attributes).map(attributeKey => (
            <EventAttributeIcon attributeKey={attributeKey} key={attributeKey} />
          ))}
        <HighlightChips rating={rating} size="small" />
        <EventTypeIcon type={type} />
      </Container>
    </Container>
  )
}

export default EventHeader
